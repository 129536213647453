import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseName',
  standalone: true,
})
export class ParseNamePipe implements PipeTransform {
  transform(value: string): string {
    return value?.split('|', 2)[0]?.trimEnd();
  }
}
