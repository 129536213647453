import { clamp } from 'lodash-es';
import {
  OperatorFunction,
  Subject,
  concat,
  filter,
  fromEvent,
  interval,
  map,
  merge,
  publish,
  repeat,
  shareReplay,
  take,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';
import { Logger } from 'timeghost-api';

export type RevalidateOptions = {
  interval: number;
};
/**
 * Revalidate observables
 *
 * * **move above calculations for recalculations**
 * @param options Revalidation Options
 * @returns revalidation operator
 */
export function revalidate<T>(options?: Partial<RevalidateOptions>): OperatorFunction<T, T> {
  const log = new Logger('rxjs.revalidate');
  const ms = clamp(options?.interval ?? 30000, 0, Number.MAX_SAFE_INTEGER);
  let interval$ = interval(ms).pipe(map(() => true));
  let focused$ = fromEvent(window, 'focus').pipe(map(() => true)),
    blurred$ = fromEvent(window, 'blur').pipe(map(() => false));
  const focusOrRefresh = merge(
    interval$.pipe(takeUntil(blurred$), repeat({ delay: () => focused$ })),
    focused$,
    blurred$,
  )
    .pipe(throttleTime(ms))
    .pipe(tap((focus) => log.debug('focus trigger', focus)));
  const subject = new Subject<T>();
  const subject$ = subject.pipe(shareReplay(1, ms));
  return publish((value) => {
    value.subscribe({
      complete() {
        subject.complete();
      },
      next: (v) => subject.next(v),
    });
    return concat(
      subject$.pipe(take(1)),
      merge(
        value.pipe(tap((v) => log.debug('value mutated', v))),
        focusOrRefresh.pipe(
          withLatestFrom(subject$),
          filter(([hasFocus]) => {
            log.debug('focus filter', hasFocus);
            return hasFocus;
          }),
          map(([, newValue]) => newValue),
          tap((newValue) => log.debug('focus re emit value', newValue)),
        ),
      ),
    ).pipe(
      tap((value) => {
        log.debug(new Date().toLocaleTimeString(), value, document.hasFocus());
      }),
    );
  });
}
